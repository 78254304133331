<template>
  <div id="metabolitesearch">
    <MyheadCompnent></MyheadCompnent>
    <div id="metabolitesearchcompnent" v-if="head !==''">
      <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
      <el-container>
        <el-main :v-if="metaboliteData2">
          <el-table :data="metaboliteData2" style="width: 100%" @row-click="detailSearch">
            <el-table-column label="PMhub ID" width="180">
              <template #default="scope">
                <p>{{ scope.row.metabolite }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Name" width="280"></el-table-column>
            <el-table-column label="Structure">
              <template #default="scope">
                <!-- <canvas :id="scope.row.metabolite" style="width: 150px;"></canvas> -->
                <div :id="scope.row.metabolite" style="width: 200px;height: 200px;"></div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="Structure">
              <template #default="scope">
                <img :src="getSvg(scope.row.svg)" style="width: 150px;">
              </template>
            </el-table-column> -->
            <el-table-column prop="molecular" label="Formula"></el-table-column>
            <el-table-column prop="molWt" label="Average Mass"></el-table-column>
            <el-table-column prop="exactMolWt" label="Monoisotopic Mass"></el-table-column>
          </el-table>
        </el-main>
      </el-container>
      <div style="display: flex; justify-content: center" v-if="metaboliteData">
        <el-pagination background layout="sizes, prev, pager, next, jumper" :total="metaboliteData.length"
          v-model:current-page="pagesize.page" v-model:page-size="pagesize.size" :page-sizes="[10,20,50]">
        </el-pagination>
      </div>
    </div>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  // import MetaboliteSearchCompnent from '@/components/Browse/MetaboliteSearchCompnent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  import request from '@/network/request'
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import 'smiles-drawer'
  import common from '@/mymethods/common.js'
  export default {
    name: 'MetaboliteSearch',
    data () {
      return {
        seachParams: {
          selectItem: '',
          searchItem: '',
        },
        pagesize: {
          page: 1,
          size: 10,
        },
        metaboliteData: '',
        metaboliteData2: '',
        head: "Requesting !",
      }
    },
    methods: {
      getSearchData () {
        request({
          url: 'metabolite/search/',
          params: this.seachParams,
        }).then(res => {
          this.metaboliteData = res
          this.head = "Metabolites List"
          let page = this.pagesize.page
          let size = this.pagesize.size
          this.metaboliteData2 = this.metaboliteData.slice(page * size - size, page * size)
        }).catch(err => {
          console.log(err);
        });
      },
      getSvg (svgStr) {
        let blob = new Blob([svgStr], {
          type: 'image/svg+xml'
        });
        let blobStr = URL.createObjectURL(blob)
        return blobStr
        // let base64 = window.btoa(svgStr)
        // let template = `<img src="data:image/svg+xml;base64,${base64}">`
        // return template
      },
      detailSearch (row, column, event) {
        this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: row.metabolite } })
        // this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: metabolite } })
      },
      getStructure (smiles, id) {
        let options = {
          width: 150,
          height: 150
        }
        let smilesDrawer = new SmilesDrawer.Drawer(options);
        // smilesDrawer.draw('C1CCCCC1', 'draw2d')
        SmilesDrawer.parse(smiles, function (tree) {
          smilesDrawer.draw(tree, id, 'light', false);
        }, function (err) {
          console.log(err);
        })
      }
    },
    components: {
      // MetaboliteSearchCompnent,
      MetaboliteBrowseCompnentSearch,
      MyheadCompnent,
      MyfooterCompnent,
    },
    created () {
      this.seachParams.selectItem = this.$route.params.selectItem
      this.seachParams.searchItem = this.$route.params.searchItem
      this.getSearchData()
    },
    // 相同路由，参数不一样，路由不跳转，但是beforeRouteUpdate可以监控
    beforeRouteUpdate (to, from, next) {
      this.seachParams.selectItem = to.params.selectItem
      this.seachParams.searchItem = to.params.searchItem
      this.getSearchData()
      next();
    },
    updated () {
    },
    mounted () {
      window.vue = this
    },
    watch: {
      pagesize: {
        handler (newName, oldName) {
          let size = newName.size
          let page = newName.page
          this.metaboliteData2 = this.metaboliteData.slice(page * size - size, page * size)
        },
        // immediate: true,
        deep: true,
      },
      metaboliteData2: {
        handler (newName, oldName) {
          this.$nextTick(() => {
            for (let item in newName) {
              // this.getStructure(newName[item].smiles, newName[item].metabolite)
              common.drawMol(newName[item].mol, newName[item].metabolite)
            }
          })
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
</style>